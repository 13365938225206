import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" sx={{ py: 5, bgcolor: 'background.default' }}>
      <Typography fontFamily="Mulish" variant="h4" gutterBottom color="white">
        {t('privacy_policy.title', 'Política de Privacidade')}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.introduction.title', '1. Introdução')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t(
          'privacy_policy.introduction.description',
          'Na Infinito Clinic, respeitamos e protegemos sua privacidade, especialmente no tratamento de dados sensíveis relacionados à saúde e estética. Esta Política de Privacidade explica como coletamos, utilizamos e protegemos seus dados pessoais, em conformidade com o Regulamento Geral de Proteção de Dados (RGPD - Regulamento (UE) 2016/679). Ao utilizar nossos serviços de medicina estética e medicina capilar, você concorda com os termos aqui descritos.'
        )}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.data_collected.title', '2. Dados Coletados')}
      </Typography>
      <List>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_collected.identification', 'Dados de identificação: Nome, data de nascimento, telefone, e-mail, endereço, documento de identidade.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_collected.health', 'Dados de saúde e estéticos: Histórico de tratamentos, diagnóstico clínico, fotos antes e depois (com consentimento), prescrições, exames e informações sobre procedimentos.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_collected.financial', 'Dados financeiros: Informações de pagamento, como dados de cartão de crédito ou débito.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_collected.navigation', 'Dados de navegação: Cookies, endereço IP e dados de uso do site para melhorar a experiência do usuário.')} /></ListItem>
      </List>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.legal_basis.title', '3. Base Legal e Finalidade do Tratamento')}
      </Typography>
      <List>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.legal_basis.consent', 'Consentimento: Para envio de comunicações de marketing e utilização de fotos com sua permissão.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.legal_basis.contract', 'Execução de contrato: Para fornecer os serviços solicitados.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.legal_basis.legal_obligation', 'Cumprimento de obrigações legais: Para atender exigências regulatórias.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.legal_basis.legitimate_interest', 'Interesse legítimo: Para melhorar nossos serviços e garantir a segurança dos tratamentos.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.legal_basis.vital_interest', 'Interesses vitais: Para proteger sua saúde ou segurança em emergências.')} /></ListItem>
      </List>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.data_sharing.title', '4. Compartilhamento de Dados')}
      </Typography>
      <List>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_sharing.health_professionals', 'Profissionais de saúde e esteticistas parceiros.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_sharing.laboratories', 'Laboratórios e clínicas parceiras.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_sharing.authorities', 'Autoridades legais e regulatórias.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.data_sharing.third_parties', 'Fornecedores de serviços terceirizados sob estrita confidencialidade.')} /></ListItem>
      </List>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.international_transfers.title', '5. Transferências Internacionais')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.international_transfers.description', 'Se houver necessidade de transferir seus dados para fora da União Europeia, garantimos que a transferência será feita conforme as proteções exigidas pelo RGPD.')}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.data_retention.title', '6. Retenção de Dados')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.data_retention.description', 'Armazenamos seus dados pelo tempo necessário para cumprir as finalidades descritas ou conforme exigido por lei.')}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.your_rights.title', '7. Seus Direitos')}
      </Typography>
      <List>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.access', 'Acesso: Solicitar uma cópia dos dados.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.correction', 'Correção: Corrigir dados incorretos.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.deletion', 'Exclusão: Solicitar a exclusão de dados, quando permitido por lei.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.restriction', 'Restrição de tratamento: Limitar o uso de seus dados.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.portability', 'Portabilidade: Transferência de dados para outra organização.')} /></ListItem>
        <ListItem><ListItemText style={{ color: "white" }} primary={t('privacy_policy.your_rights.objection', 'Oposição: Opor-se ao tratamento para fins de marketing.')} /></ListItem>
      </List>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.your_rights.contact', 'Para exercer esses direitos, entre em contato:')} <strong>contact@infinitoclinic.com</strong>
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.data_security.title', '8. Segurança dos Dados')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.data_security.description', 'Adotamos medidas técnicas e organizacionais para proteger seus dados, como criptografia, controle de acesso e auditorias periódicas.')}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.changes.title', '9. Alterações nesta Política de Privacidade')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.changes.description', 'Esta política pode ser atualizada para refletir mudanças nos nossos serviços ou em conformidade com as leis aplicáveis.')}
      </Typography>

      <Typography fontFamily="Mulish" variant="h6" gutterBottom color="white">
        {t('privacy_policy.contact.title', '10. Contato')}
      </Typography>
      <Typography fontFamily="Mulish" paragraph color="white">
        {t('privacy_policy.contact.description', 'Para dúvidas ou solicitações:')}
      </Typography>
      <Box>
        <Typography fontFamily="Mulish" color="white">{t('privacy_policy.contact.email', 'E-mail')}: contact@infinitoclinic.pt</Typography>
        {/* <Typography fontFamily="Mulish" color="white">{t('privacy_policy.contact.phone', 'Telefone')}: +351937249057</Typography> */}
      </Box>
    </Container>
  );
}
