import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grow, MenuItem, TextField } from '@mui/material';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Checkbox, FormControlLabel } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Row, Col, Card } from 'react-bootstrap';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Adaptable performance',
    image: "alluzence.jpeg",
    description:
      'Our product effortlessly adjusts to your needs, boosting efficiency and simplifying your tasks.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Great user experience',
    image: "fagron.jpeg",
    description:
      'Integrate our product into your routine with an intuitive and easy-to-use interface.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Innovative functionality',
    image: "hamonyca.jpeg",
    description:
      'Stay ahead with features that set new standards, addressing your evolving needs better than the rest.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reliable support',
    image: "juvedem.jpeg",
    description:
      'Count on our responsive customer support, offering assistance that goes beyond the purchase.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "profhilo.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "restylane.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "sculptra.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "thenct.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "havard.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "madrid.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "ordem_dos_medicos.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "preto.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "sitri.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "spme.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "logo-w.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision in every detail',
    image: "alcala.jpeg",
    description:
      'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
  },
];

const ThreeCardsCarousel = () => {
  return (
    <Carousel interval={3000} style={{
    }}
      indicators={false}
    >
      <Carousel.Item>
        <Stack style={{ width: "100%", maxHeight: 120, display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 10 }}>
          {
            items.slice(0, 4).map((item, index) => (
              <Col md={3} sx={3}>
                <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "white", borderRadius: 1, width: "90%" }}>
                  <img src={item.image} alt='land branding' style={{ borderRadius: 10, objectFit: "contain", width: 90, height: 90 }} />
                </Box>
              </Col>
            ))
          }
        </Stack>
      </Carousel.Item>

      <Carousel.Item>
        <Stack style={{ width: "100%", maxHeight: 120, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          {
            items.slice(4, 8).map((item, index) => (
              <Col md={3} sx={3}>
                <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "white", borderRadius: 1, width: "90%" }}>
                  <img src={item.image} alt='land branding' style={{ borderRadius: 10, objectFit: "contain", width: 90, height: 90 }} />
                </Box>
              </Col>
            ))
          }
        </Stack>
      </Carousel.Item>

      <Carousel.Item>
        <Stack style={{ width: "100%", maxHeight: 120, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          {
            items.slice(8, 12).map((item, index) => (
              <Col md={3} sx={3}>
                <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "white", borderRadius: 1, width: "90%" }}>
                  <img src={item.image} alt='land branding' style={{ borderRadius: 10, objectFit: "contain", width: 90, height: 90 }} />
                </Box>
              </Col>
            ))
          }
        </Stack>
      </Carousel.Item>

      <Carousel.Item>
        <Stack style={{ width: "100%", maxHeight: 120, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          {
            items.slice(12, 16).map((item, index) => (
              <Col md={3} sx={3}>
                <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "white", borderRadius: 1, width: "90%" }}>
                  <img src={item.image} alt='land branding' style={{ borderRadius: 10, objectFit: "contain", width: 90, height: 90 }} />
                </Box>
              </Col>
            ))
          }
        </Stack>
      </Carousel.Item>
    </Carousel>
  );
};

export default function Highlights() {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  }

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: '',
    country: '',
    acceptTerms: false,
    acceptPolicy: false,
  });

  const [errors, setErrors] = React.useState({});

  const countries = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BR', label: 'Brazil' },
    { value: 'BN', label: 'Brunei' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CV', label: 'Cabo Verde' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'SZ', label: 'Eswatini' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GR', label: 'Greece' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: 'North Korea' },
    { value: 'KR', label: 'South Korea' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syria' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TG', label: 'Togo' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
  ];


  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is not valid";
    }
    if (!formData.phone) tempErrors.phone = "Phone is required";
    if (!formData.country) tempErrors.country = "Please select a country";

    if (!formData.acceptTerms) tempErrors.acceptTerms = "Please accept the terms";

    if (!formData.acceptPolicy) tempErrors.acceptPolicy = "Please accept the policy";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [messageSended, setMessageSended] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Form submitted:", formData);
      setMessageSended(true);
      fetch('https://mios-direct.azurewebsites.net/account/infinity/' + formData.name + '/' + formData.email + '/' + formData.country + '/' + formData.phone, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => console.log(data))
        .catch(error => console.error('There was a problem with the fetch operation:', error));

      //reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        country: ''
      });
      // Add your submission logic here (e.g., sending to an API)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisible2, setIsVisible2] = React.useState(false);
  const sectionRef = React.useRef(null);
  const sectionRef2 = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry.isIntersecting);
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.05, // Define quando a animação dispara (30% do elemento visível)
      }
    );

    const observer2 = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry.isIntersecting);
        setIsVisible2(entry.isIntersecting);
      },
      {
        threshold: 0.05, // Define quando a animação dispara (30% do elemento visível)
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    if (sectionRef2.current) {
      observer2.observe(sectionRef2.current);
    }

    // Limpa o observador quando o componente desmonta
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      if (sectionRef2.current) {
        observer2.unobserve(sectionRef2.current);
      }
    };
  }, []);


  return (
    <>
      <Grow in={isVisible} timeout={1000}>
        <Container
          ref={sectionRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 3,
          }}
        >
          <Typography fontFamily="Mulish" variant="h3" style={{ color: "#E59E34", textAlign: "start", marginBottom: 15 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.8rem', md: '2.3rem' },
            }}
          >
            {t('leading_title')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "start", width: "100%", marginTop: 0 }}
            sx={{
              width: {
                xl: '50%',
                xs: '70%',
                md: '50%',
              },
              fontSize: { xs: '1.3rem', md: '1.7rem' },
            }}
          >
            {t('leading_subtitle')}
          </Typography>
          <Stack style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            // height: "50vh",
            marginTop: 40,
            marginBottom: 40,
          }}
            sx={{
              height: {
                xs: "50vh",
                md: 600,
              }
            }}
          >
            <img src="land_brading.jpg" alt="image" style={{ height: "100%", width: "100%", borderRadius: 10, objectFit: "cover" }} />
          </Stack>

          <Stack style={{ width: "100%", overflow: "hidden" }}>
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2
              }}
            >
              {/* {
              items.map((item, index) => (
                <Stack
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: 1,
                    color: 'white',
                    maxHeight: 100,
                    minHeight: 100,
                    maxWidth: 100,
                    minWidth: 100,
                    alignItems: "center",
                    borderRadius: 1
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={item.image} alt='land branding' style={{ borderRadius: 10, objectFit: "fill", width: 100, height: 100 }} />
                  </Box>
                </Stack>
              ))
            } */}
              {
                ThreeCardsCarousel()
              }
            </Stack>
          </Stack>
        </Container>
      </Grow>
      <Divider
        id="contactus"
        style={{
          marginTop: 10,
          marginBottom: 10,
        }} />
      <Grow in={isVisible2} timeout={1000}>
        <Stack
          ref={sectionRef2}
        >
          <Box
            id="highlights"
            sx={{
              pt: { xs: 5, sm: 5 },
              color: 'white',
              bgcolor: 'hsl(220, 30%, 2%)',
            }}
          >
            <Container
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
              }}
            >
              <Box
                sx={{
                  width: { sm: '100%', md: '100%' },
                  textAlign: { sm: 'left', md: 'center' },
                }}
              >
                <Typography fontFamily="Mulish"
                  variant="h3" style={{ color: "#E59E34", textAlign: "start", marginBottom: 15 }}
                  sx={{
                    width: "100%",
                    fontSize: { xs: '1.8rem', md: '2.3rem' },
                  }}
                >
                  {t('contact_title')}
                </Typography>
                <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "start", width: "100%", marginTop: 0 }}
                  sx={{
                    width: "100%",
                    fontSize: { xs: '1.3rem', md: '1.7rem' },
                  }}
                >
                  {t('contact_subtitle')}
                </Typography>
              </Box>
            </Container>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{}}
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">
                {"Schedule a meeting with us"}
              </DialogTitle>
              <DialogContent>
                <InlineWidget url="https://calendly.com/infinitoclinic" />
              </DialogContent>
              {/* <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Fechar
            </Button>
          </DialogActions> */}
            </Dialog>
          </Box>
          <Box
            id="highlights"
            sx={{
              pt: { xs: 5, sm: 5 },
              color: 'white',
              bgcolor: 'hsl(220, 30%, 2%)',
            }}
          >
            <Container
              id="contactus"
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
              }}
            >
              <Box
                sx={{
                  width: { sm: '100%', md: '100%' },
                  textAlign: { sm: 'left', md: 'start' },
                }}
              >
                {/* <Typography fontFamily="Mulish" variant="h5" >
              {t('contact_allow')}
            </Typography> */}
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label={t('contact_name')}
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    margin="normal"
                    variant='standard'
                  />
                  <TextField
                    fullWidth
                    label={t('contact_email')}
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="normal"
                    variant='standard'
                  />
                  <TextField
                    fullWidth
                    select
                    label={t('contact_country')}
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    error={!!errors.country}
                    helperText={errors.country}
                    margin="normal"
                    variant='standard'
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Limite de altura do menu
                            maxWidth: 300,  // Limite de largura do menu
                          },
                        },
                      },
                    }}
                  >
                    {countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    label={t('contact_phone')}
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    margin="normal"
                    variant='standard'
                  />

                  <Stack>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.acceptTerms}
                          onChange={(e) => setFormData({ ...formData, acceptTerms: e.target.checked })}
                          name="acceptTerms"
                          color="primary"
                          style={{
                            color: "#E59E34"
                          }}
                        />
                      }
                      label={t('accept_terms_label')}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.acceptPolicy}
                          onChange={(e) => setFormData({ ...formData, acceptPolicy: e.target.checked })}
                          name="acceptPolicy"
                          color="primary"
                          style={{
                            color: "#E59E34"
                          }}
                        />
                      }
                      label={t('accept_terms_label_2')}
                    />
                  </Stack>

                  {errors.acceptTerms && (
                    <Typography fontFamily="Mulish" color="error">{t('accept_terms_error')}</Typography>
                  )}

                  <Box mt={2}>
                    <Button
                      type='submit'
                      style={{ marginTop: 20, backgroundColor: "white", color: "black" }}
                      variant="contained" color="warning" size="large" fullWidth
                    >
                      {t('contact_button')}
                    </Button>
                  </Box>
                </form>
              </Box>

              <Stack
                sx={{
                  display: messageSended ? 'flex' : 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                  textAlign: 'center',
                  backgroundColor: 'green',
                  opacity: 1,
                  borderRadius: 2,
                  height: 70,
                }}
              >
                <Typography fontFamily="Mulish" variant="h5" sx={{ color: 'white' }}>
                  {t('success_message')}
                </Typography>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Grow>
      <Box sx={{
        maxWidth: {
          xs: '90%',
          sm: '90%',
          md: '70%',
        }, mx: "auto", mt: 5, backgroundColor: "red"
      }}>

      </Box>
    </>
  );
}
