import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Link, Stack, styled } from '@mui/material';
import Footer from './Footer';
import { Instagram, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Professional',
    subheader: 'Recommended',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
      'Dedicated team',
      'Best deals',
    ],
    buttonText: 'Start now',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  const { t } = useTranslation();

  const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  }));

  const SocialIcon = styled(Box)(({ theme }) => ({
    margin: '0 5px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      margin: '0 3px',
    },
  }));

  return (
    <Box
      sx={{
        color: '#fff',
        py: 4,
        px: 2,
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* World Locations */}
        <Grid item xs={12} md={3} style={{ alignItems: "center", justifyContent: "start", display: "flex", flexDirection: "column" }}>
          <Typography fontFamily="Mulish"
            variant="h3" style={{ color: "white", textAlign: "center" }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.3rem', md: '1.5rem' },
            }}
          >
            {t('foot_word_location')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>{t('foot_word_location_brazil')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>
            {t('foot_word_location_spain')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>{t('foot_word_location_portugal')}</Typography>
        </Grid>

        {/* On Your Time */}
        <Grid item xs={12} md={3} style={{ alignItems: "center", justifyContent: "start", display: "flex", flexDirection: "column" }}>
          <Typography fontFamily="Mulish"
            variant="h3" style={{ color: "white", textAlign: "center" }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.3rem', md: '1.5rem' },
            }}
          >
            {t('foot_at_your_time')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>{t('foot_monday_to_monday')}</Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>At your time</Typography>
        </Grid>

        {/* Contact */}
        <Grid item xs={12} md={3} style={{ alignItems: "center", justifyContent: "start", display: "flex", flexDirection: "column" }}>
          <Typography fontFamily="Mulish"
            variant="h3" style={{ color: "white", textAlign: "center" }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.3rem', md: '1.5rem' },
            }}
          >
            {t('foot_contact_infinity')}
          </Typography>
          <Stack sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
            marginTop: 1,
          }}>
            <SocialIcon onClick={() => {
              window.open('https://www.instagram.com/dramorenaromano', '_blank');
            }}>
              <Instagram style={{ color: '#ffffff' }} />
            </SocialIcon>
            <SocialIcon onClick={() => {
              window.open('https://wa.me/351937249057', '_blank');
            }}>
              <WhatsApp style={{ color: '#ffffff' }} />
            </SocialIcon>
          </Stack>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>
            <Link href="mailto:contact@infinitoclinic.com" color={"text.secondary"} underline="none">
              contact@infinitoclinic.com
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} style={{ alignItems: "center", justifyContent: "start", display: "flex", flexDirection: "column" }}>
          <Typography fontFamily="Mulish"
            variant="h3" style={{ color: "white", textAlign: "center" }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.3rem', md: '1.5rem' },
            }}
          >
            {t('foot_carrer')}
          </Typography>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "center", width: "100%", marginTop: 0 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1rem', md: '1.3rem' },
            }}>
            <Link href="mailto:carrer@infinitoclinic.com" color={"text.secondary"} underline="none">
              carrer@infinitoclinic.com
            </Link>
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
}
