import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import HeroInfinity from './components/HeroInfinity';
import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InlineWidget, PopupButton, PopupWidget } from 'react-calendly';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Toggle design language"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton data-screenshot="toggle-default-theme" value={false}>
          Material Design 2
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const { t } = useTranslation();
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleOpen = () => {
    setOpenDialog(true);
  }

  return (
    <>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <HeroInfinity />
      {/* <Hero /> */}
      <Box sx={{ bgcolor: 'background.default' }}>
        {/* <LogoCollection /> */}
        <Features />
        <Divider style={{ margin: 10 }} />
        <Testimonials />
        <Divider style={{ margin: 10 }} />
        <Highlights />
        <Divider style={{ margin: 10 }} />
        <Pricing />
        <Divider style={{ margin: 10 }} />
        {/* <Divider />
        <FAQ />
        <Divider /> */}
        <Footer />
      </Box>
      {/* <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      /> */}
      {/* <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{}}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Schedule a meeting with us"}
        </DialogTitle>
        <DialogContent>
          <InlineWidget url="https://calendly.com/infinitoclinic" />
        </DialogContent>
      </Dialog> */}
      {/* <Stack style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: 2,
        height: 150,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'end',
        marginRight: 15,
      }}>
        <Button
          size="large"
          style={{ marginTop: 20, backgroundColor: "white", color: "black" }}
          variant="contained" color="schedule"
          onClick={handleOpen}
        >
          {t('buuton_schedule_now')}
        </Button>
      </Stack> */}
      <PopupWidget url="https://calendly.com/infinitoclinic"
        rootElement={document.getElementById('root')}
        text={t('buuton_schedule_now')}
        color='white'
        textColor='black'
      />
    </>
  );
}
