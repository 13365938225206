import React from 'react';
import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage';
import ComingSoon from './comingSoon';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import "./i18net";
import OurStory from './components/OurStory';
import ServicePage from './components/ServicePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import { Box, Button, Fade, Link, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import "./i18n"
import AboutUs from './components/AboutUs';

function App() {
  const { t } = useTranslation();

  const [mode, setMode] = React.useState('dark');
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const [bannerOpen, setBannerOpen] = React.useState(false);

  const closeBanner = () => {
    setBannerOpen(false);
  };

  React.useEffect(() => {
    // Check if the user has visited the site before
    const allowed = localStorage.getItem('cookie-banner-allowed');
    if (allowed) {
      setBannerOpen(false);
    } else {
      setBannerOpen(true);
    }
  }, []);

  return (
    <ThemeProvider theme={LPtheme}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/mariola" element={<LandingPage />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/service/:service" element={<ServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </Router>
      {/* <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen} style={{ zIndex: 10000 }}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label={t('cookie_banner.aria_label', 'Cookie banner')}
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ justifyContent: 'space-between', gap: 2 }}
            >
              <Box sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}>
                <Typography fontFamily="Mulish" sx={{ fontWeight: 'bold' }}>
                  {t('policy_banner.title', 'Este site utiliza cookies')}
                </Typography>
                <Link variant="body2" href="/privacy-policy" underline="hover">
                  {t('policy_banner.description', 'Este site utiliza cookies para melhorar sua experiência.')}
                </Link>
              </Box>
              <Stack
                direction={{ xs: 'row-reverse', sm: 'row' }}
                sx={{ gap: 2, flexShrink: 0, alignSelf: { xs: 'flex-end', sm: 'center' } }}
              >
                <Button size="small" onClick={() => {
                  localStorage.setItem('cookie-banner-allowed', 'true');
                  closeBanner();
                }} variant="contained">
                  {t('policy_banner.allow_all', 'Permitir todos')}
                </Button>
                <Button size="small" onClick={closeBanner}>
                  {t('policy_banner.reject_all', 'Rejeitar todos')}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus> */}
    </ThemeProvider>
  );
}

export default App;

