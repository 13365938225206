import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const AestheticMedicinePage = () => {
    const { t } = useTranslation();

    const sections = [
        { title: t('refyldess.title'), description: t('refyldess.description') },
        { title: t('alluzience.title'), description: t('alluzience.description') },
        { title: t('hyaluronic_filler.title'), description: t('hyaluronic_filler.description') },
        { title: t('sculptra.title'), description: t('sculptra.description') },
        { title: t('harmonyca.title'), description: t('harmonyca.description') },
        { title: t('nctf.title'), description: t('nctf.description') },
        { title: t('pdo_threads.title'), description: t('pdo_threads.description') },
        { title: t('pdrn.title'), description: t('pdrn.description') },
        { title: t('exossomes.title'), description: t('exossomes.description') },
        { title: t('plasma_jet.title'), description: t('plasma_jet.description') },
        { title: t('prp_facial.title'), description: t('prp_facial.description') },
        { title: t('body_filler.title'), description: t('body_filler.description') },
        { title: t('body_mesotherapy.title'), description: t('body_mesotherapy.description') },
        { title: t('nutrigen.title'), description: t('nutrigen.description') },
        { title: t('telotest.title'), description: t('telotest.description') },
        { title: t('acnetest.title'), description: t('acnetest.description') }
    ];

    return (
        <Box sx={{ backgroundColor: '#000000', minHeight: '100vh', color: 'white', py: 8 }}>
            <Container maxWidth="md" sx={{ fontFamily: 'Mulish, sans-serif' }}>
                {sections.map((section, index) => (
                    <Accordion key={index} sx={{ backgroundColor: '#121212', color: 'white', mb: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography variant="h6" fontFamily="Mulish, sans-serif" fontWeight="bold">
                                {section.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8, fontFamily: 'Mulish, sans-serif' }}>
                                {section.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Box>
    );
};

export default AestheticMedicinePage;
