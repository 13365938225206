import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const HairTransplantPage = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('hair_transplant_fue_long_hair.title'),
            description: t('hair_transplant_fue_long_hair.description'),
        },
        {
            title: t('hair_transplant.title'),
            description: t('hair_transplant.description'),
        },
        {
            title: t('beard_transplant.title'),
            description: t('beard_transplant.description'),
        },
        {
            title: t('eyebrow_transplant.title'),
            description: t('eyebrow_transplant.description'),
        },
    ];

    return (
        <Box sx={{ backgroundColor: '#000000', minHeight: '100vh', color: 'white', py: 8 }}>
            <Container maxWidth="md" sx={{ fontFamily: 'Mulish, sans-serif' }}>
                {sections.map((section, index) => (
                    <Accordion key={index} sx={{ backgroundColor: '#121212', color: 'white', mb: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography variant="h6" fontFamily="Mulish, sans-serif" fontWeight="bold">
                                {section.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8, fontFamily: 'Mulish, sans-serif' }}>
                                {section.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Box>
    );
};

export default HairTransplantPage;
