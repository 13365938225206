import React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppAppBar from './AppAppBar';

export default function AboutUs() {
    const { t } = useTranslation();
    return (
        <>
            <Container maxWidth="xl" sx={{ fontFamily: 'Mulish', py: 8, backgroundColor: "black", height: "100%" }}>
                <Box textAlign="center" mb={6}>
                    <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" color={"#E59E34"} fontFamily={"Mulish"}>
                        {t('about_title', 'Sobre Nós')}
                    </Typography>
                </Box>
                <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8 }} color={"white"} fontFamily={"Mulish"}>
                    {t('about_text_one', '')}
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8 }} color={"white"} fontFamily={"Mulish"}>
                    {t('about_text_two', '')}
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8 }} color={"white"} fontFamily={"Mulish"}>
                    {t('about_text_three', '')}
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }} color={"white"} fontFamily={"Mulish"}>
                    {t('about_text_four', '')}
                </Typography>
                <Stack style={{
                    width: "100%",
                }}>
                    <Stack direction="row" spacing={2} justifyContent="center" mt={6} style={{
                        backgroundImage: "url('/morena.jpeg')",
                        height: "70vh",
                        width: "30vw",
                        backgroundSize: "cover",
                        borderRadius: 10,
                        alignSelf: "center",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }} />
                </Stack>
            </Container>
        </>
    );
}