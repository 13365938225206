import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Divider, Grow, Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ArrowUpward, ChevronLeftOutlined } from '@mui/icons-material';


const items = [
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Hair treatment',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageLight: 'treat.jpge',
    imageDark: 'treat.jpge',
  },
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Aesthetic medicine',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageLight: 'woman-4605248_1280.jpg',
    imageDark: 'woman-4605248_1280.jpg',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Hair transplant',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageDark: 'people-2568524_1280.jpg',
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background: 'linear-gradient(to bottom right, #E69F35, #DD9933)',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
        '&:focus, &:active': {
          background: 'linear-gradient(to bottom right, #E69F35, #DD9933)', // mantém o estilo de selecionado
          boxShadow: 'none', // remove efeitos de foco padrão
        },
      },
    },
    {
      props: ({ selected }) => !selected,
      style: {
        background: 'none',
        borderColor: 'hsl(0, 0%, 80%)',
        '& .MuiChip-label': {
          color: 'white',
        },
        ...theme.applyStyles('dark', {
          borderColor: 'hsl(0, 0%, 40%)',
        }),
        '&:focus, &:active': {
          background: 'none', // mantém o estilo de não selecionado
          boxShadow: 'none',
        },
      },
    },
  ],
}));


export default function Features() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [openAboutUs, setOpenAboutUs] = React.useState(false);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const [isVisible, setIsVisible] = React.useState(false);
  const sectionRef = React.useRef(null);

  const selectedFeature = items[selectedItemIndex];

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry.isIntersecting);
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.05, // Define quando a animação dispara (30% do elemento visível)
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Limpa o observador quando o componente desmonta
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Container ref={sectionRef} id="features">
      <Stack style={{
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        marginTop: 30
      }}>
        <Grow in={isVisible} timeout={1000}>
          <Typography
            fontFamily="Mulish"
            variant="h3"
            style={{ color: "#E59E34", textAlign: "start", marginBottom: 15 }}
            sx={{
              width: "100%",
              fontSize: { xs: '1.8rem', md: '2.3rem' },
            }}
          >
            {t('welcome_title')}
          </Typography>
        </Grow>
        <Grow in={isVisible} timeout={1500}>
          <Typography fontFamily="Mulish" variant="h4" style={{ color: "white", textAlign: "start", width: "100%", marginTop: 0 }}
            sx={{
              width: {
                xl: '50%',
                xs: '70%',
                md: '50%',
              },
              fontSize: { xs: '1.3rem', md: '1.7rem' },
              fontFamily: 'Mulish',
            }}
          >
            {t('welcome_subtitle')} <Link onClick={() => {
              setOpenAboutUs(true);
            }} color={"#E59E34"} style={{ cursor: "pointer" }}>{t('welcome_subtitle_2')}</Link>
          </Typography>
        </Grow>

        {
          openAboutUs && (
            <Stack maxWidth="xl" sx={{ fontFamily: 'Mulish', py: 8, height: "100%" }}>
              <Box textAlign="center" mb={0} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}>
                <Typography
                  fontFamily="Mulish"
                  variant="h3"
                  style={{ color: "#E59E34", textAlign: "start", marginBottom: 0 }}
                  sx={{
                    width: "100%",
                    fontSize: { xs: '1.8rem', md: '2.3rem' },
                  }}
                >
                  {t('about_title', 'Sobre Nós')}
                </Typography>
                <ArrowUpward style={{ color: "#E59E34", fontSize: 40, cursor: "pointer" }} onClick={() => {
                  setOpenAboutUs(false);
                }} />
              </Box>
              <Grid container spacing={6} marginTop={0}>
                <Grid item xs={12} md={4}>
                  <Stack style={{
                    width: "100%",
                  }}>
                    <Stack direction="row" spacing={2} justifyContent="center" mt={0} style={{
                      backgroundImage: "url('/morena.jpeg')",
                      height: "46vh",
                      width: "100%",
                      backgroundSize: "contain",
                      borderRadius: 10,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      marginBottom: 20
                    }} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8, fontSize: { xs: '1.3rem', md: '1.7rem' }, fontFamily: 'Mulish', }} color={"white"} fontFamily={"Mulish"}>
                    {t('about_text_one', '')}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8, fontSize: { xs: '1.3rem', md: '1.7rem' }, fontFamily: 'Mulish' }} color={"white"} fontFamily={"Mulish"}>
                {t('about_text_two', '')}
              </Typography>
              <Typography variant="body1" paragraph sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8, fontSize: { xs: '1.3rem', md: '1.7rem' }, fontFamily: 'Mulish' }} color={"white"} fontFamily={"Mulish"}>
                {t('about_text_three', '')}
              </Typography>
              <Typography variant="body1" paragraph sx={{ mb: 0, fontSize: '1.1rem', lineHeight: 1.8, fontSize: { xs: '1.3rem', md: '1.7rem' }, fontFamily: 'Mulish' }} color={"white"} fontFamily={"Mulish"}>
                {t('about_text_four', '')}
              </Typography>
            </Stack>
          )
        }

        <Grow in={isVisible} timeout={1500}>
          <Grid container spacing={6} marginTop={0}>
            <Grid item xs={12} md={3}>
              <div style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}>
                {/* <Typography fontFamily="Mulish"
                  variant="body1"
                  sx={{
                    color: '', mb: { xs: 2, sm: 0 },
                    fontSize: { xs: '4rem', md: '5.5rem' },
                  }}
                >
                  587+
                </Typography> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <CountUp
                    start={0}
                    end={1027}
                    duration={5}
                    decimal='.'
                    style={{
                      color: 'white',
                      fontSize: "5.5rem",
                    }}
                  />
                  <Typography fontFamily="Mulish"
                    variant="body1"
                    sx={{
                      color: '', mb: { xs: 2, sm: 0 },
                      fontSize: { xs: '4rem', md: '5.5rem' },
                    }}
                  >
                    +
                  </Typography>
                </div>

                <Typography fontFamily="Mulish" component="h2" variant="h4" sx={{
                  color: 'text.primary',
                  fontSize: { xs: '1.2rem', md: '1.7rem' },
                }}>
                  {t('title_service_three')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <CountUp
                    start={0}
                    end={2050}
                    duration={5}
                    decimal='.'
                    style={{
                      color: 'white',
                      fontSize: "5.5rem",
                    }}
                  />
                  <Typography fontFamily="Mulish"
                    variant="body1"
                    sx={{
                      color: '', mb: { xs: 2, sm: 0 },
                      fontSize: { xs: '4rem', md: '5.5rem' },
                    }}
                  >
                    +
                  </Typography>
                </div>
                <Typography fontFamily="Mulish" component="h2" variant="h4" sx={{
                  color: 'text.primary',
                  fontSize: { xs: '1.2rem', md: '1.7rem' },
                }}>
                  {t('title_service_two')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <CountUp
                    start={0}
                    end={1749}
                    duration={5}
                    decimal='.'
                    decimals={0}
                    style={{
                      color: 'white',
                      fontSize: "5.5rem",
                    }}
                  />
                  <Typography fontFamily="Mulish"
                    variant="body1"
                    sx={{
                      color: '', mb: { xs: 2, sm: 0 },
                      fontSize: { xs: '4rem', md: '5.5rem' },
                    }}
                  >
                    +
                  </Typography>
                </div>
                <Typography fontFamily="Mulish" component="h2" variant="h4" sx={{
                  color: 'text.primary',
                  fontSize: { xs: '1.2rem', md: '1.7rem' },
                }}>
                  {t('title_service_one')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <CountUp
                    start={0}
                    end={3}
                    duration={5}
                    decimal='.'
                    style={{
                      color: 'white',
                      fontSize: "5.5rem",
                    }}
                  />
                  <Typography fontFamily="Mulish"
                    variant="body1"
                    sx={{
                      color: '', mb: { xs: 2, sm: 0 },
                      fontSize: { xs: '4rem', md: '5.5rem' },
                    }}
                  >
                    +
                  </Typography>
                </div>
                <Typography fontFamily="Mulish" component="h2" variant="h4" sx={{
                  color: 'text.primary',
                  fontSize: { xs: '1.2rem', md: '1.7rem' },
                }}>
                  {t('locations')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grow>

        <Grow in={isVisible} timeout={2000}>
          <Stack style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "start",
            marginTop: 20
          }}>
            {/* <Button
              style={{ marginTop: 20, backgroundColor: "white", color: "black" }}
              variant="contained" color="warning" size="large"
              onClick={() =>
                navigate('/our-story')
              }
            >
              {t('welcome_button')}
            </Button> */}
          </Stack>
        </Grow>
      </Stack>
      <Grow in={isVisible} timeout={2000}>
        <Stack style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 40,
          borderRadius: 10,
        }}
          sx={{
            height: {
              xs: "50vh",
              md: 600,
            },
            backgroundImage: {
              xs: 'url(/time.jpeg)',
              md: 'url(/time.jpeg)',
            },
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // rotate 90deg
          }}
        >
          {/* <img src="woman_4.png" alt="image" style={{ height: "100%", width: "100%", borderRadius: 10, objectFit: "cover" }} /> */}
        </Stack>
      </Grow>
    </Container>
  );
}
